<template>
  <div>
    <div class="card card-flush mb-4">
      <!--begin::Card header-->
      <div class="card-header">
        <!--begin::Card title-->
        <div class="card-title">
          <h2>Order Details</h2>
        </div>
        <!--end::Card title-->
      </div>
      <!--end::Card header-->

      <!--begin::Card body-->
      <div class="card-body pt-0 fs-6">

        <!--begin::Section-->
        <div class="mb-10">

          <!--begin::Details-->
          <table class="table fs-6 fw-bold gs-0 gy-2 gx-2">

            <tr class="">
              <td class="text-gray-400">Order Number:</td>
              <td class="text-gray-800">{{ order.id }}</td>

              <td class="text-gray-400">User:</td>
              <td class="text-gray-800">{{ order.user }}</td>

              <td class="text-gray-400">Provider:</td>
              <td class="text-gray-800">{{ order.provider }}</td>

              <td class="text-gray-400">Status:</td>
              <td class="text-gray-800">
                <span class="badge fs-7 fw-bolder" :class="`badge-light-info`">{{ order.status }}</span>
              </td>

            </tr>
            <tr class="">

              <td class="text-gray-400">Service:</td>
              <td class="text-gray-800">{{ order.service }}</td>

              <td class="text-gray-400">Price Type:</td>
              <td class="text-gray-800">{{ order.price_type }}</td>

              <td class="text-gray-400">Coupon Code:</td>
              <td class="text-gray-800">{{ order.coupon_code }}</td>

              <td class="text-gray-400">Order Created Date:</td>
              <td class="text-gray-800">{{ order.date }}</td>

            </tr>

            <tr class="">

              <td class="text-gray-400">Order Time:</td>
              <td class="text-gray-800">{{ order.order_time }}</td>

              <td class="text-gray-400">Arrived Time:</td>
              <td class="text-gray-800">{{ order.arrived_time }}</td>

              <td class="text-gray-400">Start Time:</td>
              <td class="text-gray-800">{{ order.start_time }}</td>

              <td class="text-gray-400">Finish Time:</td>
              <td class="text-gray-800">{{ order.finish_time }}</td>

            </tr>

            <tr class="">

              <td class="text-gray-400" v-if="order.reject_reason">Reject Reason:</td>
              <td class="text-gray-800" v-if="order.reject_reason">{{ order.reject_reason }}</td>

              <td class="text-gray-400" v-if="order.not_paid_reason">Not Paid Reason:</td>
              <td class="text-gray-800" v-if="order.not_paid_reason">{{ order.not_paid_reason }}</td>

            </tr>

          </table>
          <!--end::Details-->
        </div>
        <!--end::Section-->

      </div>
      <!--end::Card body-->
    </div>

    <div class="row g-5 g-xl-8 mt-2">
      <div class="col-xl-8">
        <div class="card">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder text-dark">Providers</span>
            </h3>
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <div class="table-responsive m-5">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <tr class="fw-bolder fs-5 text-gray-800">
                <th>Name</th>
                <th>Status</th>
                <th>Price</th>
                <th>Reject Reason</th>
              </tr>
              <tr v-for="order_provider in order.order_providers">
                <td>{{ order_provider.provider }}</td>
                <td>
                  <span class="badge fs-7 fw-bolder" :class="`badge-light-info`">{{ order_provider.status }}</span>
                </td>
                <td>{{ order_provider.price }}</td>
                <td>{{ order_provider.reject_reason }}</td>
              </tr>
            </table>
          </div>

        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder text-dark">Options</span>
            </h3>
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <div class="table-responsive m-5">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <tr class="fw-bolder fs-5 text-gray-800">
                <th>Name</th>
                <th>Value</th>
              </tr>
              <tr v-for="option_value in order.option_values">
                <td>{{ option_value.option.name }}</td>
                <td>{{ option_value.value }}</td>
              </tr>
            </table>
          </div>

        </div>
      </div>

    </div>
    <div class="row g-5 g-xl-8 mt-2">
      <div class="col-xl-8">
        <div class="card">
          <!--begin::Header-->
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder text-dark">Payments</span>
            </h3>
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <div class="table-responsive m-5">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
              <tr>
                <th>Transaction Id</th>
                <th>Payment Method</th>
                <th>Price</th>
                <th>Status</th>
              </tr>
              <tr v-for="payment in order.payments">
                <td>{{ payment.transaction_id }}</td>
                <td>{{ payment.payment_method }}</td>
                <td>{{ payment.price }}</td>
                <td>
                  <span class="badge fs-7 fw-bolder"
                        :class="`badge-light-${payment.status.class}`">{{ payment.status.label }}</span>
                </td>
              </tr>
            </table>
          </div>

        </div>
      </div>
      <div class="col-xl-4">
        <div class="card">
          <div class="card-header align-items-center border-0 mt-4">
            <h3 class="card-title align-items-start flex-column">
              <span class="fw-bolder text-dark">Order Rate</span>
            </h3>
          </div>
          <!--end::Header-->

          <!--begin::Body-->
          <div class="table-responsive m-5">
            <table class="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">

              <tr >
                <td>Rate By User</td>
                <td><el-rate :model-value="order.rate_by_user ? order.rate_by_user.rate : 0" allow-half /></td>
              </tr>
              <tr >
                <td>Rate By Provider</td>
                <td><el-rate :model-value="order.rate_by_provider ? order.rate_by_provider.rate : 0" allow-half /></td>
              </tr>
            </table>
          </div>

        </div>
      </div>
    </div>
    <div class="card card-flush mt-4">
      <!--begin::Card header-->
      <div class="card-header">
        <!--begin::Card title-->
        <div class="card-title">
          <h2>Summary</h2>
        </div>
        <!--end::Card title-->
      </div>
      <!--end::Card header-->

      <!--begin::Card body-->
      <div class="card-body pt-0 fs-6">

        <!--begin::Section-->
        <div class="mb-10">

          <!--begin::Details-->
          <table class="table fs-6 fw-bold gs-0 gy-2 gx-2">

            <tr class="">
              <td class="text-gray-400">Price:</td>
              <td class="text-gray-800">{{ order.price }}</td>

              <td class="text-gray-400">Coupon Discount:</td>
              <td class="text-gray-800">{{ order.coupon_discount }}</td>

              <td class="text-gray-400">Application Rate:</td>
              <td class="text-gray-800">{{ order.application_rate }}</td>

              <td class="text-gray-400">Application Price:</td>
              <td class="text-gray-800">{{ order.application_price }}</td>
            </tr>

            <tr class="">
              <td class="text-gray-400">Provider Rate:</td>
              <td class="text-gray-800">{{ order.provider_rate }}</td>

              <td class="text-gray-400">Provider Price:</td>
              <td class="text-gray-800">{{ order.provider_price }}</td>

              <td class="text-gray-400">Service Charges:</td>
              <td class="text-gray-800">{{ order.service_charges }}</td>

              <td class="text-gray-400">Total Price:</td>
              <td class="text-gray-800">{{ order.total_price }}</td>
            </tr>



          </table>
          <!--end::Details-->
        </div>
        <!--end::Section-->

      </div>
      <!--end::Card body-->
    </div>

    <GMapMap
        v-if="order.locations && order.locations.length > 0"
        ref="gmap"
        :center="{lat: order.locations[0].lat, lng: order.locations[0].lng}"
        :zoom="8"
        style="width: 100%; height: 500px"
    >
      <GMapMarker
          :key="index"
          v-for="(m, index) in order.locations"
          :position="{lat : m.lat, lng : m.lng}"
          :label='{text: (index+1)+"", color: "white"}'
      >
      </GMapMarker>
    </GMapMap>
  </div>

</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import ApiAxiosService from "@/core/services/ApiAxiosService";
import {useRoute} from 'vue-router'
import {handleError} from "@/core/helpers/response";
import {APIs} from "@/store/enums/ApiEnums";

export default defineComponent({
  name: "view-order",
  components: {},

  setup() {
    const loading = ref(true);
    const order = ref({});
    const route = useRoute();
    const value = ref(3);

    onMounted(() => {
      setCurrentPageBreadcrumbs("View Orders", ["Orders"]);
    });


    const fetchData = async (id) => {
      loading.value = true;
      try {
        let response = await ApiAxiosService.get(APIs.ORDER.show(id));
        order.value = response.data.data.order;
        loading.value = false;
        console.log(order.value)
      } catch (error) {
        handleError(error)
      }

    };

    fetchData(route.params.id);

    return {
      getIllustrationsPath,
      loading,
      fetchData,
      order,
      value,
    };
  },

});
</script>
